<i18n>
ru:
  averageTime: 'Время приготовления составит {min} минут'
  averageTimeDelivery: 'Время доставки составит {min} минут'
  changedMinSubByRule: 'Минимальная сумма заказа сейчас {price}'
  dataConsent: Я даю согласие на обработку своих персональных данных.
  errorClientInBlacklist: Вы находитесь в чёрном списке ресторана и не можете оформить заказ
  errorDeliveryCity: Выберите город.
  errorDeliveryDepartment: Выберите отдел.
  errorDeliveryDistrict: Выберите район.
  errorDeliveryHouse: Укажите дом.
  errorDeliveryStreet: Укажите улицу.
  errorDeliveryTerminal: Выберите терминал.
  errorEmpty: Корзина пуста. Добавьте в неё что-нибудь.
  errorForbidden: Некоторые товары в корзине недоступны для заказа. Проверьте корзину.
  errorInHall: Не выбран стол или ресторан для заказа в зале.
  errorInternalSettings: 'Ошибка при расчёте стоимости доставки. Некорректно настроены
    правила работы с платной доставкой, свяжитесь со специалистами'
  errorInvalid: 'В корзине присутствуют продукты, не прошедшие валидацию.'
  errorMinSum: 'Извините, но минимальная стоимость заказа - {minsum} руб. Просто закажите что-нибудь ещё.'
  errorMinSumGeneral: Не достигнута минимальная стоимость заказа. Просто закажите что-нибудь ещё.
  errorOrderDestinationIsNotValid: Не заполнены все поля для доставки/самовывоза.
  errorOrderType: Выберите способ получения заказа.
  errorPay: Не выбран тип оплаты!
  errorPersonalInfo: Укажите имя и номер телефона.
  errorPromoCodeIsInvalid: Промокод просрочен!
  errorRecipient: Не выбран способ получения чека
  errorSelfService: Не выбран ресторан для самовывоза.
  errorUnknown: Неизвестная ошибка!
  minDeliverySum: 'Минимальная стоимость вашего заказа:'
  notBlockingNo: Назад
  notBlockingYes: Продолжить оформление
  personalDataMessage: 'Оформляя заказ, Вы соглашаетесь с '
  personalDataMessageLink: условиями обработки персональных данных
  secondStep: К оформлению заказа
  submit: Оформить заказ
ua:
  averageTime: 'час приготування складе {min} хвилин'
  averageTimeDelivery: 'час доставки складе {min} хвилин'
  changedMinSubByRule: 'Мінімальна сума замовлення зараз {price}'
  dataConsent: Я даю згоду на обробку персональних даних.
  errorClientInBlacklist: Ви перебуваєте в чорному списку ресторану і не можете оформити замовлення
  errorDeliveryCity: Оберіть місто.
  errorDeliveryDepartment: Оберіть відділ.
  errorDeliveryDistrict: Оберіть район.
  errorDeliveryHouse: Вкажіть будинок.
  errorDeliveryStreet: Вкажіть вулицю.
  errorDeliveryTerminal: Оберіть термінал.
  errorEmpty: Кошик пустує. Додайте до нього що-небудь.
  errorForbidden: У кошику присутні товари неможливі для замовлення вибраним способом.
  errorInHall: Не обраний стіл або ресторан для замовлення в залі.
  errorInternalSettings: Помилка при розрахунку вартості доставки. Некоректно налаштовані правила роботи з платною доставкою. Будь ласка зв’яжіться з фахівцями.
  errorInvalid: 'У кошику присутні товари, які не пройшли валідацію.'
  errorMinSum: 'Пробачте, но мінімальна вартість замовлення — {minsum} грн. Замовте
    щось додатково.'
  errorMinSumGeneral: 'Пробачте, но не досягнута мінімальна сума замовлення. Замовте
    щось додатково.'
  errorOrderDestinationIsNotValid: Не заповнені всі поля для доставки / самовивозу.
  errorOrderType: Оберіть спосіб отримання замовлення.
  errorPay: Не вибрано тип оплати
  errorPersonalInfo: Вкажіть ім’я та номер телефону.
  errorPromoCodeIsInvalid: Промокод прострочений!
  errorRecipient: Не обрано спосіб отримання чеку
  errorSelfService: Не вибрано ресторан для самовивозу.
  errorUnknown: Невідома помилка!
  minDeliverySum: 'Сума замовлення:'
  notBlockingNo: Назад
  notBlockingYes: Продовжити оформлення
  personalDataMessage: 'Оформляючи замовлення, Ви погоджуєтеся з '
  personalDataMessageLink: умовами обробки персональних даних
  secondStep: До оформлення замовлення
  submit: Оформити замовлення
us:
  averageTime: 'Cooking time will be {min} minutes'
  averageTimeDelivery: 'Delivery time will be {min} minutes'
  changedMinSubByRule: 'The minimum order amount is now {price}'
  dataConsent: I agree to the terms of use.
  errorClientInBlacklist: You are on the restaurant blacklist and cannot place an order
  errorDeliveryCity: Select delivery city.
  errorDeliveryDepartment: Select delivery department.
  errorDeliveryDistrict: Select delivery area.
  errorDeliveryHouse: Specify your house.
  errorDeliveryStreet: Specify your street.
  errorDeliveryTerminal: Select delivery terminal.
  errorEmpty: Your cart is empty. Add something to it.
  errorForbidden: Some items in your cart are unavailable for purchase. Check your cart.
  errorInHall: No table or restaurant has been selected for ordering in hall.
  errorInternalSettings: Error while calculating delivery price. Incorrect delivery settings. Please contact support
  errorInvalid: Cart have items that were not validated.
  errorMinSum: 'Sorry, but minimum order amount is {minsum}. Just order something else.'
  errorMinSumGeneral: 'Sorry, but minimum order amount has not been reached. Just order
    something else.'
  errorOrderDestinationIsNotValid: Not all fields required for delivery/pickup are filled in.
  errorOrderType: Select a way to receive your order.
  errorPay: Payment type not chosen
  errorPersonalInfo: Enter your name and phone number.
  errorPromoCodeIsInvalid: The promo code is expired!
  errorRecipient: Receipt method not selected
  errorSelfService: You haven’t selected a restaurant for self-service.
  errorUnknown: Unknown error!
  minDeliverySum: 'Minimal order cost:'
  notBlockingNo: Cancel
  notBlockingYes: Proceed with your order
  personalDataMessage: 'By placing an order, you agree to '
  personalDataMessageLink: the terms of personal data processing
  secondStep: Proceed to order
  submit: Submit order
</i18n>

<template><slot /></template>

<script setup lang="ts">
const appConfig = useAppConfig()

const clientStore = useClientStore()
const restaurantStore = useRestaurantStore()
const popupStore = usePopupStore()

const { pathname } = useUrl()
const { translate } = useI18nSanitized()

onMounted(async () => {
  //handle redirect from server
  if (window.location.hash !== '') {
    switch (window.location.hash) {
      case '#cart-noitems': {
        await popupStore.showError(
          translate('cartCommonPage.errorEmpty'),
          'empty-invalid-or-forbidden'
        )
        break
      }
      case '#forbidden-to-order':
      case '#cart-forbidden': {
        await popupStore.showError(
          translate('cartCommonPage.errorForbidden'),
          'empty-invalid-or-forbidden'
        )
        break
      }
      case '#cart-ivalid':
      case '#data-invalid': {
        await popupStore.showError(
          translate('cartCommonPage.errorInvalid'),
          'empty-invalid-or-forbidden'
        )
        break
      }
      case '#city-notfound': {
        await popupStore.showError(translate('cartCommonPage.errorDeliveryCity'))
        break
      }
      case '#district-notfound': {
        await popupStore.showError(translate('cartCommonPage.errorDeliveryDistrict'))
        break
      }
      case '#cart-minsumnotreached': {
        await popupStore.showError(
          translate('cartCommonPage.errorMinSumGeneral'),
          'min-sum-not-reached'
        )
        break
      }
    }

    //this will remove hash, so that error message will not be repeated
    window.history.replaceState(null, '', pathname)
  }
})

watch(
  () => clientStore.ClientState.data?.DeliveryPaymentInfo.ModifiedMinSum ?? 0,
  async (newValue: number, oldValue: number) => {
    if (
      newValue !== oldValue &&
      clientStore.ClientState.data?.DeliveryPaymentInfo.ChangedMinSumByRule
    ) {
      await popupStore.showWarning(
        `${translate('cartCommonPage.changedMinSubByRule', {
          price:
            clientStore.ClientState.data?.DeliveryPaymentInfo.ModifiedMinSum ?? 0
        })}${restaurantStore.CurrencySymbol}`
      )
    }
  },
  { deep: true }
)

watch(
  () => clientStore.ClientState.data?.AverageTimeSec ?? 0,
  async (newValue: number, oldValue: number) => {
    if (
      import.meta.client &&
      newValue !== 0 &&
      newValue !== oldValue &&
      appConfig.VueSettingsPreRun.AverageTimeDisplayWarning &&
      newValue >= appConfig.VueSettingsPreRun.AverageTimeDisplayWarningThreshold * 60
    ) {
      await popupStore.showWarning(
        translate(
          clientStore.courierDelivery
            ? 'cartCommonPage.averageTimeDelivery'
            : 'cartCommonPage.averageTime',
          {
            min: Math.round(
              (newValue + (clientStore.ClientState.data?.DelayTimeSec ?? 0)) / 60
            )
          }
        )
      )
    }
  }
)
</script>
